import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  constructor() { }

  consoleLog(label, data){
   //console.log(label, data)
  }
}
